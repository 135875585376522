function BackButton() {
  return (
    <a
      href="https://unlock.att.com"
      className="absolute right-0 top-0 mt-4 mr-4 z-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.365"
        height="18.365"
        viewBox="0 0 18.365 18.365"
      >
        <g transform="translate(-354.134 -102.614)">
          <path
            d="M0,0V21.972"
            transform="translate(371.085 104.028) rotate(45)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            d="M0,0V21.972"
            transform="translate(371.085 119.565) rotate(135)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </a>
  )
}

export default BackButton
