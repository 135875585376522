import React, { useStage } from "react"
// import videoStream from "./helpers/videoStream"
// import html2canvas from "html2canvas"
import filter1 from "./assets/img/filter-1.png"
import filter2 from "./assets/img/filter-2.png"
import filter3 from "./assets/img/filter-3.png"
import filter4 from "./assets/img/filter-4.png"
import BackButton from "./components/BackButton"
import rotateIcon from "./assets/img/rotate-device-icon.png"
import Camera from "./components/Camera"
import Result from "./components/Result"
import Loading from "./components/Loading"

class App extends React.Component {
  constructor(props) {
    super(props)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleRetakeClick = this.handleRetakeClick.bind(this)
    this.handleAdvanceInstructions = this.handleAdvanceInstructions.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.onLangClick = this.onLangClick.bind(this)
    this.onTaglineClick = this.onTaglineClick.bind(this)
    this.handleUnsupportedDevice = this.handleUnsupportedDevice.bind(this)
    this.handleCameraError = this.handleCameraError.bind(this)
    this.handleChangeCameraFacing = this.handleChangeCameraFacing.bind(this)
    // this.positionBottomContent = this.positionBottomContent.bind(this)
    this.handlePositionContent = this.handlePositionContent.bind(this)
    this.onSwipe = this.onSwipe.bind(this)
    this.handleInstructionsCompleted =
      this.handleInstructionsCompleted.bind(this)
    this.state = {
      imageData: null,
      showInstructions: true,
      instructionNumber: 0,
      spanish: false,
      textWhite: true,
      filters: [filter1, filter2, filter3, filter4],
      selectedFilter: 0,
      loading: true,
      shortDevice: false,
      bottomBarHeight: undefined,
      unsupportedDevice: false,
      cameraError: false,
      cameraFaceUser: true,
    }
  }

  setLoading(state) {
    this.setState({
      loading: state,
    })
  }

  onLangClick() {
    // if (this.state.showInstructions) return
    this.setState({
      spanish: !this.state.spanish,
    })
  }
  onTaglineClick() {
    // if (this.state.showInstructions) return
    this.setState({
      textWhite: !this.state.textWhite,
    })
  }

  onSwipe(direction) {
    // Stop filter change if on instructions and not on the filter slide
    if (this.state.showInstructions && this.state.instructionNumber !== 0)
      return

    // Increment or decrement the position in the filters array until you reach the end or begining.  In which case we start a the opposite end
    if (direction === "left") {
      if (this.state.selectedFilter === this.state.filters.length - 1) {
        this.setState({
          selectedFilter: 0,
        })
      } else {
        this.setState({
          selectedFilter: this.state.selectedFilter + 1,
        })
      }
    } else if (direction === "right") {
      if (this.state.selectedFilter === 0) {
        this.setState({
          selectedFilter: this.state.filters.length - 1,
        })
      } else {
        this.setState({
          selectedFilter: this.state.selectedFilter - 1,
        })
      }
    }
    // console.log("selected filter is # " + this.state.selectedFilter)
  }

  handleUnsupportedDevice() {
    this.setState({
      unsupportedDevice: true,
      loading: false,
    })
  }

  handleCameraError() {
    this.setState({
      cameraError: true,
      loading: false,
    })
  }

  handleChangeCameraFacing(cameraFaceUser) {
    this.setState({
      cameraFaceUser: cameraFaceUser,
    })
  }

  handleAdvanceInstructions() {
    if (this.state.instructionNumber === 3) {
      this.handleInstructionsCompleted()
    } else {
      this.setState({
        instructionNumber: this.state.instructionNumber + 1,
      })
    }
  }

  handleInstructionsCompleted() {
    this.setState({
      showInstructions: false,
    })
  }

  handleImageChange(data) {
    this.setState({
      imageData: data,
    })
  }

  handleRetakeClick() {
    this.setLoading(true)
    this.setState({
      imageData: null,
    })
  }

  handlePositionContent() {
    const bottomBar = document.querySelectorAll(".bottomBar")
    bottomBar.forEach((bar) => {
      // console.log("bottom bar: " + bar.offsetHeight)
      if (bar.offsetHeight < 90) {
        this.setState({
          shortDevice: true,
          bottomBarHeight: bar.offsetHeight,
        })
      } else {
        this.setState({
          shortDevice: false,
          bottomBarHeight: bar.offsetHeight,
        })
      }
    })
  }

  componentDidMount() {
    this.handlePositionContent()
    window.addEventListener("resize", () => this.handlePositionContent())
  }

  render() {
    let display
    if (this.state.unsupportedDevice) {
      display = (
        <div className="flex flex-col items-center justify-center w-full h-full text-center bg-gradient-to-t from-dark-blue to-light-blue">
          <BackButton />
          <p className="w-4/5 m-auto text-white">
            It looks like your device doesn't have a camera or doesn't support
            taking images in the browser. Please use a different device or
            upgrade your software to the latest version.
          </p>
        </div>
      )
    } else if (this.state.cameraError) {
      display = (
        <div className="flex flex-col items-center justify-center w-full h-full text-center bg-gradient-to-t from-dark-blue to-light-blue">
          <BackButton />
          <p className="w-4/5 m-auto text-white">
            You need to grant permission to your camera in order to get the full
            experience.
          </p>
        </div>
      )
    } else {
      if (this.state.imageData) {
        display = (
          <Result
            imageData={this.state.imageData}
            onRetakeClick={this.handleRetakeClick}
            spanish={this.state.spanish}
            filter={this.state.filters[this.state.selectedFilter]}
            key="result"
            setLoading={this.setLoading}
            textWhite={this.state.textWhite}
            shortDevice={this.state.shortDevice}
            bottomBarHeight={this.state.bottomBarHeight}
            cameraFaceUser={this.state.cameraFaceUser}
          />
        )
      } else {
        display = (
          <Camera
            onImageChange={this.handleImageChange}
            showInstructions={this.state.showInstructions}
            handleInstructionsCompleted={this.handleInstructionsCompleted}
            spanish={this.state.spanish}
            textWhite={this.state.textWhite}
            onLangClick={this.onLangClick}
            onTaglineClick={this.onTaglineClick}
            filter={this.state.filters[this.state.selectedFilter]}
            selectedFilter={this.state.selectedFilter}
            filters={this.state.filters}
            onSwipe={this.onSwipe}
            key="camera"
            instructionNumber={this.state.instructionNumber}
            handleAdvanceInstructions={this.handleAdvanceInstructions}
            setLoading={this.setLoading}
            shortDevice={this.state.shortDevice}
            handlePositionContent={this.handlePositionContent}
            bottomBarHeight={this.state.bottomBarHeight}
            handleUnsupportedDevice={this.handleUnsupportedDevice}
            handleCameraError={this.handleCameraError}
            handleChangeCameraFacing={this.handleChangeCameraFacing}
          />
        )
      }
    }
    return (
      <div className="relative w-full h-full overflow-hidden App">
        {display}
        <div className="fixed top-0 right-0 h-full overlayBar bg-gradient-to-r from-blue to-cobalt"></div>
        <div className="fixed top-0 left-0 h-full overlayBar bg-gradient-to-r from-cobalt to-blue"></div>
        <div className="absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full text-center portrait:hidden bg-gradient-to-t from-dark-blue to-light-blue">
          <BackButton />
          <img
            src={rotateIcon}
            alt="Please rotate your device"
            className="w-1/4 max-w-xs mb-8"
          />
          <p className="text-2xl text-white md:text-3xl">
            Please rotate your device
          </p>
        </div>
        {this.state.loading ? <Loading /> : ""}
      </div>
    )
  }
}

export default App
