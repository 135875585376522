import React from "react"

import TagEng from "./TagEng"
import TagSpan from "./TagSpan"
import { ReactComponent as StarsEng } from "../assets/img/stars-eng.svg"
import { ReactComponent as StarsSpan } from "../assets/img/stars-span.svg"

class TagLine extends React.Component {
  render() {
    let tagline
    this.props.spanish
      ? (tagline = [
          <TagSpan key="labelSpan" textWhite={this.props.textWhite} />,
          <StarsSpan
            key="starsSpan"
            className={`absolute right-0 top-0 w-1/3 transform translate-x-3/4 ${
              this.props.showInstructions ? "hidden" : ""
            }`}
          />,
        ])
      : (tagline = [
          <TagEng key="labelEng" textWhite={this.props.textWhite} />,
          <StarsEng
            key="starsEng"
            className={`absolute right-0 top-0 w-1/3 transform translate-x-3/4 ${
              this.props.showInstructions ? "hidden" : ""
            }`}
          />,
        ])
        
    return (
      <div className={`absolute top-0 w-full py-8`}>
        <button
          onClick={this.props.onTaglineClick}
          className={`w-1/2 m-auto relative ${
            this.props.instructionNumber === 0 ? "hidden" : "block"
          } ${
            this.props.instructionNumber > 1 && this.props.showInstructions
              ? "-z-1"
              : "z-0"
          }`}
        >
          {tagline}
        </button>
      </div>
    )
  }
}

export default TagLine
