import React from "react"
import html2canvas from "html2canvas"
import sizeCaptureArea from "../helpers/sizeCaptureArea"
import BackButton from "./BackButton"
import TagEng from "./TagEng"
import TagSpan from "./TagSpan"
import starsEng from "../assets/img/stars-eng.png"
import starsSpan from "../assets/img/stars-span.png"

class Result extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const output = document.getElementById("output")
    const image = document.getElementById("image")
    sizeCaptureArea(output)
    window.addEventListener("resize", () => sizeCaptureArea(output))

    html2canvas(image).then((canvas) => {
      const finalImage = document.createElement("img")
      const dataImage = canvas.toDataURL("image/jpeg")
      finalImage.setAttribute("src", dataImage)
      image.innerHTML = ""
      image.appendChild(finalImage)
      finalImage.classList.add(
        "absolute",
        "top-0",
        "left-0",
        "w-full",
        "h-auto"
      )
      this.props.setLoading(false)
    })
  }

  render() {
    return (
      <div className="relative flex flex-col w-full h-full output bg-gradient-to-t b-black">
        <div id="output" className="relative m-auto overflow-hidden">
          <div id="image" className="w-full h-full">
            <div
              className={`absolute top-0 left-0 w-full h-auto ${
                this.props.cameraFaceUser ? "reflect" : ""
              }`}
            >
              <img
                src={this.props.imageData}
                alt=""
                id="photo"
                className="w-full h-auto transform scale-101"
              />
            </div>
            <div className="absolute top-0 left-0 w-full overflow-hidden h-1/4">
              <div
                className={`bg-gradient-to-b from-dark-blue to-transparent h-full transform scale-101 opacity-60 py-8 ${
                  this.props.showInstructions ? "hidden" : ""
                }`}
              ></div>
            </div>
            <div className="absolute top-0 bottom-0 left-0 right-0 w-full py-8">
              <div className="relative w-1/2 m-auto">
                {this.props.spanish
                  ? [
                      <TagSpan
                        key="tagspan"
                        textWhite={this.props.textWhite}
                      />,
                      <img
                        alt=""
                        key="starsspan"
                        src={starsSpan}
                        className="absolute top-0 right-0 w-1/3 transform translate-x-2/3"
                      />,
                    ]
                  : [
                      <TagEng key="tageng" textWhite={this.props.textWhite} />,
                      <img
                        alt=""
                        key="starseng"
                        src={starsEng}
                        className="absolute top-0 right-0 w-1/3 transform translate-x-2/3"
                      />,
                    ]}
              </div>
            </div>
            <img
              alt=""
              src={this.props.filter}
              className="absolute left-0 w-full -bottom-px"
            />
          </div>
          <button
            onClick={this.props.onRetakeClick}
            className="absolute top-0 left-0 flex items-center justify-between p-4 text-lg text-white"
          >
            <div className="w-4 h-4 transform rotate-45 border-b-2 border-l-2 border-w"></div>
            <span className="pt-1 leading-none">Retake</span>
          </button>
          <BackButton />
        </div>
        <div className="flex-1 w-full bottomBar bg-gradient-to-t from-cobalt to-blue">
          <div
            id="saveInstructions"
            style={{
              height: `${
                this.props.bottomBarHeight > 90
                  ? this.props.bottomBarHeight + "px"
                  : "auto"
              }`,
              bottom: `${
                this.props.bottomBarHeight < 90
                  ? this.props.bottomBarHeight + "px"
                  : "0"
              }`,
            }}
            className={`flex items-center justify-center w-full ${
              this.props.shortDevice
                ? "fixed py-8 bg-gradient-to-t from-black to-transparent"
                : "fixed"
            }`}
          >
            <p className="text-center text-white">
              Press and hold image <br /> to save and share
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Result
