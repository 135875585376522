import React from "react"

class LangSwitcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div
        className={`absolute top-0 pt-4 pl-4 ${
          this.props.instructionNumber < 2 ? "hidden" : "block"
        } ${
          this.props.instructionNumber > 2 && this.props.showInstructions
            ? "-z-1"
            : "z-1"
        }`}
      >
        <button
          onClick={this.props.onLangClick}
          className="border-2 border-white rounded-full py-1 px-3 uppercase text-white leading-none text-sm"
        >
          <span className="block -mb-0.5">{this.props.spanish ? "SPAN" : "ENG"}</span>
        </button>
      </div>
    )
  }
}

export default LangSwitcher
