import React from "react"

class Filter extends React.Component {
  render() {
    return (
      <img
        src={this.props.filter}
        alt=""
        className={`absolute -bottom-px left-0 w-full h-auto ${
          this.props.instructionNumber !== 0 ? "-z-1" : ""
        }`}
      />
    )
  }
}

export default Filter
