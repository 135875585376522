import loadingStars from "../assets/img/loading-stars.svg"
export default function Loading() {
  return (
    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full z-50 bg-gradient-to-t from-dark-blue to-light-blue">
      <div className="text-center">
        <img
          src={loadingStars}
          alt=""
          className="inline-block mb-6 w-16 h-auto"
        />
        <p className="text-white">Loading. . .</p>
      </div>
    </div>
  )
}
