import React from "react"
import { ReactComponent as Arrow } from "../assets/img/arrow.svg"

function Screen1() {
  return (
    <div className="flex items-center justify-center w-full m-auto absolute top-1/3">
      <Arrow className="transform rotate-180" />
      <p className="mx-6 font-bold">
        Swipe to change <br /> filter overlay
      </p>
      <Arrow />
    </div>
  )
}
function Screen2() {
  return (
    <div className="m-auto pt-2 absolute top-1/4 w-full">
      <Arrow className="transform -rotate-90 m-auto" />
      <p className="px-3 mt-4 font-bold">
        Tap to change <br /> text color
      </p>
    </div>
  )
}
function Screen3() {
  return (
    <div className="absolute top-0 pl-4 pt-24">
      <Arrow className="transform -rotate-90" />
      <p className="px-3 mt-4 font-bold">Tap to change language</p>
    </div>
  )
}
function Screen4(props) {
  return (
    <div
      className={`absolute w-full ${
        props.props.shortDevice ? "bottom-1/4" : "bottom-0 pb-4"
      }`}
    >
      <p className="px-3 mt-8 font-bold m-auto">Then snap a shot</p>
      <Arrow className="transform rotate-90 m-auto" />
    </div>
  )
}

class Instructions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnLabel: ["Next", "Next", "Next", "Got it!"],
    }
  }

  render() {
    let content
    if (this.props.instructionNumber === 0) {
      content = <Screen1 />
    } else if (this.props.instructionNumber === 1) {
      content = <Screen2 />
    } else if (this.props.instructionNumber === 2) {
      content = <Screen3 />
    } else {
      content = <Screen4 props={this.props} />
    }
    return (
      <div className="w-full h-full absolute top-0 left-0">
        <div className="relative w-full h-full bg-black bg-opacity-50">
          <div className="w-full text-white text-center h-full">{content}</div>
          <div className="absolute w-full top-1/2 z-10">
            <button
              onClick={() => this.props.handleAdvanceInstructions()}
              id="btnAdvanceInstructions"
              className="bg-white text-dark-blue p-4 pt-5 rounded-full block w-3/5 m-auto"
            >
              {this.state.btnLabel[this.props.instructionNumber]}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Instructions
