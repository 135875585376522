function sizeCaptureArea(elem) {
  const aspectRatio = window.innerWidth / window.innerHeight
  const overlayBars = document.querySelectorAll(".overlayBar")
  if (elem) {
    if (aspectRatio > 0.75) {
      /**
       * Set the height of the elem to the height of the viewport
       * Set the width of the elem relative to its height
       */
      elem.style.height = window.innerHeight + "px"
      elem.style.width = window.innerHeight * 0.25 * 3 + "px"

      /**
       * Size transparent side bars
       */
      overlayBars.forEach((bar) => {
        bar.style.width = `${(window.innerWidth - elem.offsetWidth) / 2}px`
      })
    } else {
      /**
       * set the width of the elem to the width of the viewport
       * set the height relative to its width
       */
      elem.style.width = window.innerWidth + "px"
      elem.style.height = (window.innerWidth / 3) * 4 + "px"
      overlayBars.forEach((bar) => {
        bar.style.width = 0
      })
    }
  }
}

export default sizeCaptureArea
