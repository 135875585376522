import React from "react"
import { CSSTransitionGroup } from "react-transition-group"
import { ReactComponent as RotateCameraIcon } from "../assets/img/rotate-camera.svg"
import videoStream from "../helpers/videoStream"
import Filter from "./Filter"
import BackButton from "./BackButton"
import Instructions from "./Instructions"
import sizeCaptureArea from "../helpers/sizeCaptureArea"
import LangSwitcher from "./LangSwitcher"
import TagLine from "./TagLine"

class Camera extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      touchsurface: null,
      cameraFaceUser: true,
      cameraBtnClicked: false,
    }
    this.handleImageChange = this.handleImageChange.bind(this)
  }

  /*
   * Capture current contents of video and draw to canvas.
   * Then use a second temp canvas to crop the video to the correct size
   */
  handleImageChange(data) {
    this.props.onImageChange(data)
  }
  changeCamera() {
    this.props.setLoading(true)
    this.setState(
      {
        cameraFaceUser: !this.state.cameraFaceUser,
      },
      () => {
        this.props.handleChangeCameraFacing(this.state.cameraFaceUser)
        videoStream(
          document.getElementById("video"),
          () => this.props.setLoading(false),
          this.state.cameraFaceUser ? "user" : "environment"
        )
      }
    )
  }
  takePicture() {
    // Handle if someone tries to take a picture during the tutorial
    if (this.props.showInstructions) {
      if (this.state.cameraBtnClicked) {
        this.props.handleAdvanceInstructions()
        return
      } else {
        document
          .getElementById("btnAdvanceInstructions")
          .classList.add("wiggle")
        this.setState({
          cameraBtnClicked: true,
        })
        return
      }
    }

    this.props.setLoading(true)
    const captureArea = document.getElementById("touchsurface")
    const videoCanvas = document.getElementById("videoCanvas")
    const croppedCanvas = document.createElement("canvas")
    const cropContext = croppedCanvas.getContext("2d")
    const settings = {
      smoothingEnabled: true,
      smoothingQuality: "high",
    }
    const cropDimensions = {
      // Get the percentage smaller that the capture area is than the window height to determine canvas height
      imageHeight:
        videoCanvas.height * (captureArea.offsetHeight / window.innerHeight),
      fromTop: 0,
      get imageWidth() {
        return this.imageHeight * 0.25 * 3
      },
      get fromLeft() {
        return (videoCanvas.width - this.imageWidth) * 0.5
      },
    }

    croppedCanvas.width = cropDimensions.imageWidth
    croppedCanvas.height = cropDimensions.imageHeight
    cropContext.imageSmoothingEnabled = settings.smoothingEnabled
    cropContext.smoothingQuality = settings.smoothingQuality
    cropContext.drawImage(
      videoCanvas,
      cropDimensions.fromLeft,
      cropDimensions.fromTop,
      cropDimensions.imageWidth,
      cropDimensions.imageHeight,
      0,
      0,
      cropDimensions.imageWidth,
      cropDimensions.imageHeight
    )

    const data = croppedCanvas.toDataURL("image/jpeg")

    this.handleImageChange(data)
  }

  handleSwipe(e) {
    // Set the distance that needs to be swiped before registering a swipe
    const tolerance = 40
    if (this.state.startTouchX > this.state.endTouchX + tolerance) {
      this.props.onSwipe("left")
    } else if (this.state.startTouchX < this.state.endTouchX - tolerance) {
      this.props.onSwipe("right")
    }
  }
  handleTouchStart(e) {
    this.setState({
      startTouchX: e.pageX,
    })
  }
  handleTouchEnd(e) {
    this.setState({
      endTouchX: e.pageX,
    })
    this.handleSwipe()
  }

  componentDidMount() {
    if (
      "mediaDevices" in navigator &&
      "getUserMedia" in navigator.mediaDevices
    ) {
      // Size the capture area
      sizeCaptureArea(document.getElementById("touchsurface"))
      window.addEventListener("resize", () =>
        sizeCaptureArea(document.getElementById("touchsurface"))
      )

      // Start the video stream
      videoStream(document.getElementById("video"), (msg, error) => {
        if (error) {
          console.error(`${msg} \n ${error}`)
          this.props.handleCameraError()
        }
        this.props.setLoading(false)
      })

      // Add swipe ability
      const touchsurface = document.getElementById("touchsurface")
      touchsurface.addEventListener("touchstart", (e) =>
        this.handleTouchStart(e)
      )
      touchsurface.addEventListener("touchemove", function (e) {
        e.preventDefault()
      })
      touchsurface.addEventListener("touchend", (e) => this.handleTouchEnd(e))
    } else {
      this.props.handleUnsupportedDevice()
    }

    // Position things on the screen
    this.props.handlePositionContent()
  }

  render() {
    return (
      <div className="w-full h-full">
        <div className="relative w-full h-full camera">
          <div className="absolute top-0 left-0 w-full h-full">
            <div
              id="videoWrapper"
              className="relative w-full h-full overflow-hidden"
            >
              <div className="absolute flex flex-col w-full h-full">
                <div className="relative bg-white pt-4/3">
                  <div
                    className={`bg-gradient-to-t from-transparent to-dark-blue opacity-70 py-8 absolute top-0 left-0 w-full h-1/4 ${
                      this.props.showInstructions ? "hidden" : ""
                    }`}
                  ></div>
                </div>
                <div className="flex-1 bg-white"></div>
              </div>
              <canvas
                id="videoCanvas"
                className={`absolute top-0 left-0 object-cover w-full h-full mix-blend-multiply ${
                  this.state.cameraFaceUser ? "reflect" : ""
                }`}
              ></canvas>
              <video
                id="video"
                autoPlay={true}
                playsInline={true}
                // className="absolute top-0 left-0 object-cover w-full h-full mix-blend-multiply reflect -z-1"
                className="absolute -z-1"
              ></video>
            </div>
          </div>
          <div className="absolute flex flex-col w-full h-full">
            <div id="touchsurface" className="relative z-50 m-auto">
              <BackButton />
              {/* The instructions overlay */}
              {this.props.showInstructions ? (
                <Instructions
                  instructionNumber={this.props.instructionNumber}
                  handleAdvanceInstructions={
                    this.props.handleAdvanceInstructions
                  }
                  shortDevice={this.props.shortDevice}
                  bottomBarHeight={this.props.bottomBarHeight}
                />
              ) : (
                ""
              )}

              {/* The Language switcher button */}
              <LangSwitcher
                spanish={this.props.spanish}
                onLangClick={this.props.onLangClick}
                instructionNumber={this.props.instructionNumber}
                showInstructions={this.props.showInstructions}
              />

              {/* The Tagline */}
              <TagLine
                spanish={this.props.spanish}
                onTaglineClick={this.props.onTaglineClick}
                showInstructions={this.props.showInstructions}
                instructionNumber={this.props.instructionNumber}
                textWhite={this.props.textWhite}
              />

              {/* The filter image */}
              <CSSTransitionGroup
                transitionName="filters"
                // tansitionEnterTimeout={500}
                // tansitionLeaveTimeout={300}
              >
                <Filter
                  filter={this.props.filter}
                  key={this.props.filter}
                  showInstructions={this.props.showInstructions}
                  instructionNumber={this.props.instructionNumber}
                />
              </CSSTransitionGroup>

              {/* The camera button */}
              <div
                // id="bottomContent"
                style={{
                  height: `${
                    this.props.bottomBarHeight > 90
                      ? this.props.bottomBarHeight + "px"
                      : "auto"
                  }`,
                }}
                className={`bottom-0 left-0 w-full text-center flex items-center justify-center ${
                  this.props.shortDevice ? "pt-2 pb-6 absolute" : "fixed"
                }`}
              >
                <button
                  className={`w-14 h-14 absolute left-1/4 transform -translate-x-full ${
                    this.props.showInstructions ? "hidden" : ""
                  }`}
                  onClick={() => this.changeCamera()}
                >
                  <RotateCameraIcon className="w-full h-full" />
                </button>
                <button
                  className={`border-4 border-white inline-block w-16 h-16 rounded-full p-1 shadow-md ${
                    this.props.instructionNumber < 3 ? "hidden" : ""
                  }`}
                  onClick={() => this.takePicture()}
                >
                  <div className="w-full h-full bg-white rounded-full"></div>
                </button>
              </div>
            </div>
            <div className="flex-1 bottomBar bg-gradient-to-r from-cobalt to-blue bg-opacity-90"></div>
          </div>
        </div>
        <canvas id="canvas" className="hidden"></canvas>
      </div>
    )
  }
}

export default Camera
