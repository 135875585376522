export default function videoStream(videoElem, callback, facingMode = "user") {
  const constraints = {
    audio: false,
    video: {
      facingMode: facingMode,
      focusMode: "auto",
      width: { ideal: 3000 },
      height: { ideal: 3000 },
    },
  }

  function setConstraints(track) {
    const capabilities = track.getCapabilities()
    const maxWidth = capabilities.width.max
    const maxHeight = capabilities.height.max
    if (maxHeight < maxWidth) {
      constraints.video.width.ideal = maxHeight
      constraints.video.height.ideal = maxHeight
      track.applyConstraints({
        width: maxHeight,
        height: maxHeight,
      })
    }
    window.constraints = constraints
  }

  function handleSuccess(stream, videoElem) {
    const canvas = document.getElementById("videoCanvas")
    const videoTracks = stream.getVideoTracks()
    if (videoTracks[0].getCapabilities) {
      setConstraints(videoTracks[0])
    }

    videoElem.srcObject = stream

    // Write the video to canvas to avoid browser inconsistencies with videos
    const ctx = canvas.getContext("2d")
    videoElem.addEventListener("play", () => {
      canvas.height = videoElem.videoHeight
      canvas.width = videoElem.videoWidth
      function step() {
        ctx.drawImage(videoElem, 0, 0, canvas.width, canvas.height)
        requestAnimationFrame(step)
      }
      requestAnimationFrame(step)
    })

    callback()
  }

  function handleError(error) {
    // callback(error)
    if (error.name === "ConstraintNotSatisfiedError") {
      const v = constraints.video
      callback(
        `The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.`
      )
    } else if (error.name === "PermissionDeniedError") {
      callback(
        "Permissions have not been granted to use your camera and " +
          "microphone, you need to allow the page access to your devices in " +
          "order for the demo to work."
      )
    }
    callback(`getUserMedia error: ${error.name}`, error)
  }

  function errorMsg(msg, error) {
    if (typeof error !== "undefined") {
      return error
    }
    return msg
  }

  async function init(videoElem) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      handleSuccess(stream, videoElem)
      // e.target.disabled = true
    } catch (e) {
      handleError(e)
    }
  }
  init(videoElem)
}
